var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"\n    component\n    position-relative\n    c-series\n    padding-y-24\n    bp-768:padding-y-48\n  ",class:{ dark: _vm.section.dark_mode },style:({
    'background-image': 'url(' + _vm.section.backgroundImage + ') ',
  })},[_c('div',{staticClass:"row padding-y-12 position-relative z-index-3"},[_c('header',{staticClass:"display-flex width-100 padding-x-12"},[_c('div',{staticClass:"\n          c-series__header--info\n          align-items-center\n          display-flex\n          flex-item-1\n        "},[(_vm.section.logo)?_c('div',{staticClass:"c-series__header--logo margin-r-24"},[_c('img',{attrs:{"src":_vm.section.logo + '?width=100',"alt":_vm.section.title}})]):_vm._e(),_c('div',{staticClass:"\n            c-series__header--content\n            padding-x-24\n            flex-item-1\n            display-flex\n            flex-direction-column\n            justify-content-center\n          "},[(_vm.section.subHeadline)?_c('div',{staticClass:"\n              c-series__header--logo\n              margin-b-8\n              font-weight-700 font-size-16\n              bp-768:font-size-18\n              line-height-125\n            "},[_vm._v(" "+_vm._s(_vm.section.subHeadline)+" ")]):_vm._e(),(_vm.section.headline)?_c('h2',{staticClass:"\n              margin-0\n              font-size-21\n              bp-768:font-size-48\n              line-height-125\n              font-weight-700\n            "},[_vm._v(" "+_vm._s(_vm.section.headline)+" ")]):_vm._e()])]),(
          _vm.section.showCountdown &&
          _vm.section.seriesGames &&
          _vm.section.seriesGames.length > 0
        )?_c('div',{staticClass:"c-series__countdown"},[_c('vue-countdown',{staticClass:"c-series__countdown__wrapper",attrs:{"time":_vm.time},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var days = ref.days;
        var hours = ref.hours;
        var minutes = ref.minutes;
        var seconds = ref.seconds;
return [_c('div',{staticClass:"c-series__countdown--time margin-x-12 bp-1280:margin-x-24"},[_c('div',[_vm._v(_vm._s(days))]),_c('div',[_vm._v("Days")])]),_c('div',{staticClass:"c-series__countdown--time margin-x-12 bp-1280:margin-x-24"},[_c('div',[_vm._v(_vm._s(hours))]),_c('div',[_vm._v("Hours")])]),_c('div',{staticClass:"c-series__countdown--time margin-x-12 bp-1280:margin-x-24"},[_c('div',[_vm._v(_vm._s(minutes))]),_c('div',[_vm._v("Minutes")])]),_c('div',{staticClass:"c-series__countdown--time margin-x-12 bp-1280:margin-x-24"},[_c('div',[_vm._v(_vm._s(seconds))]),_c('div',[_vm._v("Seconds")])])]}}],null,false,4269364182)})],1):_vm._e()])]),_c('div',{staticClass:"row padding-y-12 position-relative z-index-3"},[_c('swiper',{ref:"swiperTop",staticClass:"\n        c-series__list\n        flex-item-1\n        width-100\n        padding-6\n        bp-768:display-flex\n      ",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.section.seriesGames),function(event){return _c('swiper-slide',{key:event.game.id,staticClass:"display-flex"},[_c('div',{staticClass:"\n            c-series__desktop c-series__item\n            display-flex\n            flex-direction-column\n          "},[_c('div',{staticClass:"c-series__item--inner"},[_c('header',{staticClass:"\n                c-series__item--header\n                display-flex\n                align-items-center\n                justify-content-flex-end\n                flex-item-1\n              "},[(
                  event.game &&
                  event.game.coverage &&
                  event.game.coverage.tickets
                )?_c('div',{staticClass:"c-series__item--tickets font-size-21 margin-r-auto"},[_c('a',{attrs:{"href":event.game.coverage.tickets}},[_c('i',{staticClass:"sf-tickets"})])]):_vm._e(),(
                  event.game &&
                  event.game.opponent &&
                  event.game.opponent.logo
                )?_c('div',{staticClass:"c-series__item--logo"},[_c('img',{attrs:{"src":event.game.opponent.logo + '?width=74',"alt":event.game.opponent.title + ' ' + event.game.id}})]):_vm._e(),_c('div',{staticClass:"\n                  c-series__item--title\n                  font-size-16 font-weight-700\n                  line-height-150\n                "},[_vm._v(" "+_vm._s(event.label)+" ")])]),_c('div',{staticClass:"c-series__item--content padding-b-12"},[(
                  event.game &&
                  event.game.opponent &&
                  event.game.opponent.title
                )?_c('div',{staticClass:"\n                  c-series__item--opponent\n                  font-size-16 font-weight-700\n                  line-height-150\n                  margin-b-8\n                "},[(event.game.indicator === 'A')?_c('span',[_vm._v("at")]):_vm._e(),(event.game.indicator === 'H')?_c('span',[_vm._v("vs.")]):_vm._e(),(event.game.indicator === 'N')?_c('span',[_vm._v("at")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(event.game.opponent.title))])]):_vm._e(),_c('div',{staticClass:"\n                  c-series__item--datetime\n                  font-size-16 font-weight-400\n                  line-height-150\n                  margin-b-8\n                  flex-item-1\n                  display-flex\n                  justify-content-flex-start\n                "},[_c('span',[_vm._v(" "+_vm._s(event.game.date ? _vm.moment(event.game.date).format("dddd, MMMM DD") : "N/A"))]),_c('span',{staticClass:"font-weight-700 margin-l-auto"},[_vm._v(" "+_vm._s(event.game.time))])]),(event.game && event.game.location)?_c('div',{staticClass:"c-series__item--location font-size-14 line-height-150"},[_vm._v(" "+_vm._s(event.game.location)+" ")]):_vm._e()]),_c('div',{staticClass:"c-series__item--links"},[_c('ul',{staticClass:"reset-list"},[(event.game.result)?[(
                      event.game.result.result_opponent_score &&
                      event.game.result.result_team_score &&
                      event.game.result.result_status
                    )?_c('li',[_c('a',{style:({
                        cursor: 'default',
                        textDecoration: 'none',
                      })},[_vm._v(" "+_vm._s(((event.game.result.result_status) + ", " + (event.game.result.result_team_score) + "-" + (event.game.result.result_opponent_score) + (event.game.result.result_postscore_info ? " " + event.game.result.result_postscore_info : "")))+" ")])]):_vm._e(),(
                      event.game.postgame_story &&
                      event.game.postgame_story.link
                    )?[_c('li',[_c('a',{attrs:{"href":event.game.postgame_story.link}},[_vm._v(" Recap ")])])]:_vm._e(),(event.game.result && event.game.result.boxscore_url)?[_c('li',[_c('a',{attrs:{"href":event.game.result.boxscore_url}},[_vm._v(" Box Score ")])])]:_vm._e()]:[_vm._l((event.coverageElements),function(link){return [(!link.is_custom)?[(
                          link.id == 'tv' &&
                          event &&
                          event.game &&
                          event.game.coverage.tv &&
                          link.show
                        )?_c('li',{key:link.id},[(event && event.game && event.game.coverage.tv)?_c('span',{staticClass:"c-coverage__primary"},[_vm._v(" "+_vm._s(event.game.coverage.tv)+" ")]):_vm._e()]):_vm._e(),(
                          link.id == 'radio' &&
                          event &&
                          event.game &&
                          event.game.coverage.radio &&
                          link.show
                        )?_c('li',{key:link.id},[(
                            event && event.game && event.game.coverage.radio
                          )?_c('span',{staticClass:"c-coverage__primary"},[_vm._v(" "+_vm._s(event.game.coverage.radio)+" ")]):_vm._e()]):_vm._e(),(
                          link.id == 'streaming' &&
                          event &&
                          event.game &&
                          event.game.coverage.streaming
                        )?_c('li',{key:link.id},[(
                            event &&
                            event.game &&
                            event.game.coverage.streaming
                          )?_c('a',{staticClass:"c-coverage__primary",attrs:{"href":event.game.coverage.streaming}},[_vm._v(" Watch Live ")]):_vm._e()]):_vm._e(),(
                          link.id == 'audio' &&
                          event &&
                          event.game &&
                          event.game.coverage.audio &&
                          link.show
                        )?_c('li',{key:link.id},[(
                            event && event.game && event.game.coverage.audio
                          )?_c('a',{staticClass:"c-coverage__primary",attrs:{"href":event.game.coverage.audio}},[_vm._v(" Listen Live ")]):_vm._e()]):_vm._e(),(
                          link.id == 'stats' &&
                          event &&
                          event.game &&
                          event.game.coverage.stats &&
                          link.show
                        )?_c('li',{key:link.id},[(
                            event && event.game && event.game.coverage.stats
                          )?_c('a',{staticClass:"c-coverage__primary",attrs:{"href":event.game.coverage.stats}},[_vm._v(" Live Stats ")]):_vm._e()]):_vm._e(),(
                          link.id == 'tickets' &&
                          event &&
                          event.game &&
                          event.game.coverage.tickets &&
                          link.show
                        )?_c('li',{key:link.id},[(
                            event && event.game && event.game.coverage.tickets
                          )?_c('a',{staticClass:"c-coverage__primary",attrs:{"href":event.game.coverage.tickets}},[_vm._v(" Tickets ")]):_vm._e()]):_vm._e(),(
                          link.id == 'pregame_story' &&
                          event &&
                          event.game &&
                          event.game.pregame_story &&
                          event.game.pregame_story.id &&
                          link.show
                        )?_c('li',{key:link.id},[(
                            event &&
                            event.game &&
                            event.game.pregame_story &&
                            event.game.pregame_story.id
                          )?_c('a',{staticClass:"c-coverage__primary",attrs:{"href":event.game.pregame_story.link}},[_vm._v(" Story Preview ")]):_vm._e()]):_vm._e(),(
                          link.id == 'teamStoreURL' &&
                          event &&
                          event.coverage &&
                          event.coverage.teamStoreURL &&
                          link.show
                        )?_c('li',{key:link.id},[(
                            event &&
                            event.coverage &&
                            event.coverage.teamStoreURL
                          )?_c('a',{staticClass:"c-coverage__primary",attrs:{"href":event.coverage.teamStoreURL}},[_vm._v(" Shop Online ")]):_vm._e()]):_vm._e()]:_vm._e()]}),_vm._l((event.coverageElements),function(link){return [(link.show && link.link_url)?_c('li',{key:link.id},[(link.link_url)?_c('a',{attrs:{"href":link.link_url}},[_vm._v(_vm._s(link.link_text))]):_vm._e()]):_vm._e()]})]],2)]),_c('div',{staticClass:"c-series__item--teams font-size-14 line-height-150"},[(event && event.game && event.game.awayTeam)?_c('div',{staticClass:"c-series__item--team c-series__item--team-away"},[_c('span',[_vm._v(" "+_vm._s(event.game.awayTeam.title)+":")]),_c('ul',{staticClass:"reset-list"},[_vm._l((event.game.awayTeam.links),function(link,index){return [(link.url)?_c('li',{key:index},[_c('a',{attrs:{"href":link.url}},[_vm._v(_vm._s(link.title))])]):_vm._e()]})],2)]):_vm._e(),(event && event.game && event.game.homeTeam)?_c('div',{staticClass:"c-series__item--team c-series__item--team-away"},[_c('span',[_vm._v(" "+_vm._s(event.game.homeTeam.title)+":")]),_c('ul',{staticClass:"reset-list"},[_vm._l((event.game.homeTeam.links),function(link,index){return [(link.url)?_c('li',{key:index},[_c('a',{attrs:{"href":link.url}},[_vm._v(_vm._s(link.title))])]):_vm._e()]})],2)]):_vm._e()])]),(
              event &&
              event.playerSpotlight &&
              event.playerSpotlight.players.length > 0
            )?_c('div',{staticClass:"c-series__item--spotlight"},[(event.playerSpotlight.heading)?_c('div',{staticClass:"font-size-14 line-height-150 font-weight-700"},[_vm._v(" "+_vm._s(event.playerSpotlight.heading)+" ")]):_vm._e(),_c('ul',{staticClass:"reset-list"},_vm._l((event.playerSpotlight.players),function(player){return _c('li',{key:player.player_id,staticClass:"c-series__player margin-t-12 display-flex"},[(player.image)?_c('div',{staticClass:"c-series__player--image"},[_c('img',{attrs:{"src":player.image + '?width=54',"alt":player.first_name +
                      ' ' +
                      player.last_name +
                      ' ' +
                      player.player_id}})]):_vm._e(),_c('div',{staticClass:"c-series__player--content flex-item-1 padding-24"},[(player.firstname != '' || player.last_name != '')?_c('div',{staticClass:"\n                      c-series__player--name\n                      font-size-14 font-weight-400\n                      line-height-150\n                    "},[_vm._v(" "+_vm._s(player.first_name + " " + player.last_name)+" ")]):_vm._e(),(player.position)?_c('div',{staticClass:"c-series__player--position"},[_vm._v(" "+_vm._s(player.position)+" ")]):_vm._e()])])}),0)]):_vm._e()])])}),1)],1),_c('div',{staticClass:"\n      position-relative\n      z-index-3\n      width-100\n      padding-t-12\n      swiper-arrows\n      display-flex\n      justify-content-center\n    "},[_c('div',{staticClass:"\n        swiper-button-prev3\n        margin-x-12\n        swiper-button-white\n        c-common__arrow-alt c-common__arrow\n        display-flex\n      ",attrs:{"slot":"button-prev"},slot:"button-prev"},[_c('feather',{attrs:{"size":"24px","type":"arrow-left"}})],1),_c('a',{staticClass:"\n        s-common__button s-common__button-large\n        c-schedule__more\n        text-align-center\n        margin-0\n        font-size-14\n        line-height-125\n        font-weight-700\n        display-flex\n      ",attrs:{"href":'/sports/' + this.$root.gameday.sport.shortname + '/schedule/'}},[_c('feather',{staticClass:"margin-r-6",attrs:{"size":"16px","type":"calendar"}}),_vm._v(" Full Schedule")],1),_c('div',{staticClass:"\n        swiper-button-next3\n        margin-x-12\n        swiper-button-white\n        display-flex\n        c-common__arrow-alt c-common__arrow\n      ",attrs:{"slot":"button-next"},slot:"button-next"},[_c('feather',{attrs:{"size":"24px","type":"arrow-right"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }